"use client";
import Image from 'next/image'
export function Type(props:{type:string , level:number}) {
  let path=""
  switch (props.type){
      case 'neutral':
        path="/images/types/neutral.png"
      break;

      case 'ground':
      
      path="/images/types/ground.png"
      break;

      
      case 'fire':
        
      path="/images/types/fire.png"
        
      break;

      
      case 'dragon':
      
      path="/images/types/dragon.png"
      break;

      
      case 'electric':
      
      path="/images/types/electric.png"
      break;

      
      case 'grass':
      
      path="/images/types/grass.png"
      break;

      
      case 'water':
      
      path="/images/types/water.png"
      break;

      
      case 'dark':
      
      path="/images/types/water.png"
      break;

      
      case 'ice':
        
      path="/images/types/ice.png"
      
      break;

      
      case 'watering':
        
      path="/images/suitabilities/Watering_Icon.webp"
      
      break;


      
      case 'cooling':
        
      path="/images/suitabilities/Cooling_Icon.webp"
      
      break;


      
      case 'generating_function':
        
      path="/images/suitabilities/Generating_Electricity_Icon.webp"
      
      break;

      
      case 'medicine_production':
        
      path="/images/suitabilities/Medicine_Production_Icon.webp"
      
      break;

      default:
        path=""
      break;

  }

  return (
    <span className='flex flex-row p-[3px] h-8 w-8 rounded-lg'>
      
        <Image
                src={path} 
                width={500}
                height={500}
                alt={props.type}
          />
    </span>
  );
}
