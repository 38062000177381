"use client"
import { AvatarImage, Avatar } from "~/components/ui/avatar"
import React, { useState } from 'react';
import Image from 'next/image'
import Popup from 'reactjs-popup';
import { Card, CardContent } from '~/components/ui/card';
import { Suitability } from './suitability';

import  Palinfo from '~/components/component/pal-info';
import { Type } from "./type";

import { Badge } from "~/components/ui/badge"
//
const modalOverlayStyle = {
  backdropFilter: "blur(3px)",

}
type Pal = {
  id: number;
  key: string;
  image: string;
  name: string;
  wiki: string;
  types: string[];
  imageWiki: string;
  suitability: {
    type: string;
    image: string;
    level: number;
  }[];
  drops: string[];
  aura: {
    name: string;
    description: string;
    tech: any;
  };
  description: string;
  skills: {
    level: number;
    name: string;
    type: string;
    cooldown: number;
    power: number;
    description: string;
  }[];
  stats: {
    hp: number;
    attack: {
      melee: number;
      ranged: number;
    };
    defense: number;
    speed: {
      ride: number;
      run: number;
      walk: number;
    };
    stamina: number;
    support: number;
    food: number;
  };
  asset: string;
  genus: string;
  rarity: number;
  price: number;
  size: string;
  maps: {
    day: string;
    night: string;
  };
  breeding: {
    rank: number;
    order: number;
    child_eligble: boolean;
    male_probability: number;
  };
};

function popup(props:{pal:Pal , forceopen:boolean , breed:any, targetBreed:any}) {
  const pal=props.pal
  const [open, setOpen] = useState(props.forceopen ? true : false);
  const closeModal = () => setOpen(false);

 
  return (
    <>
      <div  onClick={(e:any) =>{
    
        if(e.target.id=="breed"){
        
          props.breed(pal)
        
        }else if(e.target.id=="targetBreed"){
          props.targetBreed(pal)
         
        }else{
          setOpen(o => !o)
         
        } 
        }
      } 
      key={pal.name}
        >
        <Card className="bg-[#1B263B] border border-[#415A77] hover:border-[#dfd6d3] relative">
          <span className="absolute  top-0 right-0">
            <span className="absolute top-2 right-2  inline-flex ">
           
              <Badge className="mt-[3px]" variant="secondary">{pal.id}</Badge>
              < span className=" flex flex-row ">{pal.types.map((type)=>{return <Type key={type+pal.name+pal.aura} level={2} type={type} ></Type>})}</span> 
            </span>
          </span>
          <CardContent className="ml-[1px] h-[140px] justify-between">
            
            <div className="flex items-center space-x-2 ">
              <Avatar>
                <Image
                src={pal.image}
                width={500}
                height={500}
                alt="Picture of the author"
              />

              </Avatar>
              <div className="flex flex-col ">
                <span className="font-bold text-white">{pal.name}</span>
                <span className="text-sm text-[#8892B0]">{pal.genus}</span>
                
              </div>
              <div className="flex items-center space-x-2">
   
              </div>
            </div>
            
            <div className="flex items-center justify-between ml-2 mt-2">
              <span className="text-sm text-[#8892B0]">{pal.drops.map((drop,index)=>{return <span key={drop+pal.name}>{drop.replace(/_/g, " ")}{index === pal.drops.length - 1 ? "" : ", "}</span>})}</span>
              <div className="flex items-center space-x-2">
             
              </div>
              
            </div>
            
            <div className="flex items-center space-x-2">
                {pal.suitability.map((x)=>{return <Suitability key={x.type+pal.id+pal.name} level={x.level} suitability={x.type}></Suitability>})}
                {/* <FlameIcon className="text-[#E63946]" />
                <GlassWaterIcon className="text-[#457B9D]" />
                <GlobeIcon className="text-[#2A9D8F]" /> */}
              </div>

              
           
          </CardContent>
          <div className="grid grid-cols-2 m-2 space-x-2 self-end">
             <button  id="breed" className="text-slate-200 bg-slate-900 border border-gray-200 rounded-lg p-[3px]">Breed</button>
             
             
             <button  id="targetBreed" className="text-slate-200 border bg-slate-900 border-gray-200 rounded-lg p-[3px]">Target Breed</button>
            
              </div>
        </Card>
      </div>
      
      <Popup open={open}  closeOnDocumentClick lockScroll  overlayStyle={modalOverlayStyle } onClose={closeModal}>
        <Palinfo pal={pal}></Palinfo>
      </Popup>
    </>
  );
};

export default popup;