'use client';
import { Input } from "~/components/ui/input"
import { Button } from "~/components/ui/button"
import { AvatarImage, Avatar } from "~/components/ui/avatar"
import { CardContent, Card } from "~/components/ui/card"
import Image from 'next/image'
import { useEffect, useRef, useState } from "react";
import { Suitability } from "./suitability";
import Popup from "./popup"
import BabyPowerCalculator, { Parent, BreedingPath } from "./../utils/babyCalculator";

import { Filters } from "~/components/component/filters";
import { Head } from "next/document";
import CookieConsentBanner from "./cookie";


type Pal = {
  id: number;
  key: string;
  image: string;
  name: string;
  wiki: string;
  types: string[];
  imageWiki: string;
  suitability: {
    type: string;
    image: string;
    level: number;
  }[];
  drops: string[];
  aura: {
    name: string;
    description: string;
    tech: any;
  };
  description: string;
  skills: {
    level: number;
    name: string;
    type: string;
    cooldown: number;
    power: number;
    description: string;
  }[];
  stats: {
    hp: number;
    attack: {
      melee: number;
      ranged: number;
    };
    defense: number;
    speed: {
      ride: number;
      run: number;
      walk: number;
    };
    stamina: number;
    support: number;
    food: number;
  };
  asset: string;
  genus: string;
  rarity: number;
  price: number;
  size: string;
  maps: {
    day: string;
    night: string;
  };
  breeding: {
    rank: number;
    order: number;
    child_eligble: boolean;
    male_probability: number;
  };
};


type breedData = {
  id:number,
  Name:string,
  power:number
}



  function PalGrid( ) {
   
  const [breed,setreed]=useState<Pal[]>()
  const [targetBreed,settargetBreed]=useState<Pal[]>()
  const [Pals,setPals]=useState<Pal[]>()
  const [filtered,setFiltered]=useState(Pals)
  const [suitfiltered,setSuitFiltered]=useState(Pals)
  let breedingdata:breedData;


  const search=(text:any)=>{
      if(text.suit==true){
    if(text.value=="reset"){
      setSuitFiltered(filtered);
  
    }else{
   
      setSuitFiltered(filtered?.filter((pal:Pal)=>{return pal.name.toLowerCase().replace(/ /g, "_").includes(text.value.trim().replace(/ /g, "_")) || pal.drops.toString().toLowerCase().includes(text.value.trim().replace(/ /g, "_")) || pal.suitability.map((suit)=>{return suit.type+" "}).toString().toLowerCase().includes(text.value.trim().replace(/ /g, "_"))}));
  
    }
  }else{
    
    let filter=text.target.value.toLowerCase();
    const tmp =Pals?.filter((pal:Pal)=>{return pal.name.toLowerCase().replace(/ /g, "_").includes(filter.trim().replace(/ /g, "_")) || pal.drops.toString().toLowerCase().includes(filter.trim().replace(/ /g, "_")) || pal.suitability.map((suit)=>{return suit.type+" "}).toString().toLowerCase().includes(filter.trim().replace(/ /g, "_"))});
    setFiltered(tmp)
      setSuitFiltered(tmp)
  }
  }


  const inputRef:any = useRef(null);


  useEffect(() => {
    import("./../pals.json")
      .then((data) => {
        setFiltered(data.default as unknown as Pal[])
        setPals(data.default as unknown as Pal[])
        setSuitFiltered(data.default as unknown as Pal[])
        inputRef.current.focus();
      })
      .catch((error) => {
      });

      import("./../breeding.json")
      .then((data) => {
       breedingdata=data as unknown as breedData;
     
      })
      .catch((error) => {
      });

      const script = document.createElement("script");  
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1617300496631636";  
      script.async = true;  
      document.body.appendChild(script);  

  }, []);
  
  const setBreed=(pal:Pal)=>{
    let tmp=breed
    if(tmp==undefined){
      
      setreed([pal])
      
    }
    if(tmp&& !tmp.some((comp)=>{return comp.id==pal.id})){
      tmp?.push(pal)
      setreed([...tmp])
     
    }
   
   
  }
  const setTargetBreed=(pal:Pal)=>{
    let tmp=targetBreed
    if(tmp==undefined){
      
      settargetBreed([pal])
      
    }
    if(tmp&& !tmp.some((comp)=>{return comp.id==pal.id})){
      // tmp?.push(pal)
      // settargetBreed([...tmp])
      settargetBreed([pal])
  
    }
   
   
  }



function calculateBabyPower(parent1Power: number, parent2Power: number): number {
  return Math.floor((parent1Power + parent2Power + 1) / 2);
}

// function extractParentsFromPaths(paths: BreedingPath[]): Parent[] {
//   const parents: Parent[] = [];

//   for (const path of paths) {
//       parents.push(...path.parents);
      
// const gg = new BabyPowerCalculator([...path.parents,{power:path.babyPower}]);
// const d: BreedingPath[] = gg.findBreedingPaths(depth, targetBabyPower);

//   }

//   return parents;
// }

// // Example usage:
// const parents: Parent[] = [
//   { power: 10 },
//   { power: 1500 },
 
  
// ];

// const babyPowerCalculator = new BabyPowerCalculator(parents);
// const depth = 2;
// const targetBabyPower = 755;

// const breedingPaths: BreedingPath[] = babyPowerCalculator.findBreedingPaths(depth, targetBabyPower);

// extractParentsFromPaths(breedingPaths)


  return (
    <div className="bg-[#0D1B2A] text-white min-h-screen">
     
      <header className="bg-[#1B263B] p-4">
        <div className="flex justify-between items-center max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold">PalDeck</h1>
        </div>
      </header>
      <section className="max-w-[1600px] mx-auto mt-6">
        <div className="flex flex-col space-y-4">
          <Input ref={inputRef} onChange={search} className="bg-[#1B263B] border border-[#415A77] text-white" placeholder="Search ..." />
          <Filters filter={search} suitabilities={{}}></Filters>
          <div>

          </div>
          {breed ? <><h2 className="text-xl font-bold">Breeding Pool</h2>
          <div className="grid-cols-12 rounded-lg gap-0 grid bg-[#1f2937] p-4 text-white ">
         
          {breed?.map((x)=>{return <Avatar key={x.id}>
                <Image
                src={x.image}
                width={50}
                height={50}
                alt="Picture of the author"
              />

              </Avatar>})}
          </div>
          </>:<></>}
          {targetBreed ? <><h2 className="text-xl font-bold">Breeding Target</h2>
          <div className="grid-cols-12 rounded-lg gap-0 grid bg-[#1f2937] p-4 text-white ">
         
          {targetBreed?.map((x)=>{return <Avatar key={x.id}>
                <Image
                src={x.image}
                width={50}
                height={50}
                alt="Picture of the author"
              />

              </Avatar>})}
          </div>
          </>:<></>}
          
        </div>
      </section>
      <section className="max-w-[1600px] mx-auto mt-4 grid grid-cols-1 gap-4 tablet:grid-cols-2 laptop:grid-cols-4">
      {  suitfiltered?.map((pal:Pal)=>{
        return <Popup targetBreed={setTargetBreed} breed={setBreed} key={pal.name} pal={pal} forceopen={false}></Popup>
      })}
      
      </section>
    </div>
  )
}


function FlameIcon(props:any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" />
    </svg>
  )
}


function GlassWaterIcon(props:any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.2 22H8.8a2 2 0 0 1-2-1.79L5 3h14l-1.81 17.21A2 2 0 0 1 15.2 22Z" />
      <path d="M6 12a5 5 0 0 1 6 0 5 5 0 0 0 6 0" />
    </svg>
  )
}


function GlobeIcon(props:any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="2" x2="22" y1="12" y2="12" />
      <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
    </svg>
  )
}


function HeartIcon(props:any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
    </svg>
  )
}

export default PalGrid;