/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/w13A0Rxxrp5
 */
import { Badge } from "~/components/ui/badge"
import { AvatarImage, Avatar } from "~/components/ui/avatar"
import Link from "next/link"
import { Suitability } from "~/app/_components/suitability";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Image from 'next/image'




type Pal = {
  id: number;
  key: string;
  image: string;
  name: string;
  wiki: string;
  types: string[];
  imageWiki: string;
  suitability: {
    type: string;
    image: string;
    level: number;
  }[];
  drops: string[];
  aura: {
    name: string;
    description: string;
    tech: any;
  };
  description: string;
  skills: {
    level: number;
    name: string;
    type: string;
    cooldown: number;
    power: number;
    description: string;
  }[];
  stats: {
    hp: number;
    attack: {
      melee: number;
      ranged: number;
    };
    defense: number;
    speed: {
      ride: number;
      run: number;
      walk: number;
    };
    stamina: number;
    support: number;
    food: number;
  };
  asset: string;
  genus: string;
  rarity: number;
  price: number;
  size: string;
  maps: {
    day: string;
    night: string;
  };
  breeding: {
    rank: number;
    order: number;
    child_eligble: boolean;
    male_probability: number;
  };
};
export default function palInfo(props:{pal:Pal}) {
  let pal=props.pal
  return (
      
       <div className=" bg-gray-800 p-6 rounded-lg shadow-lg w-[90dvw] h-[90dvh] overflow-y-auto scrollbar-hide  text-white">

      <div className="flex items-center justify-between">
        <Badge variant="secondary">{pal.id}</Badge>
        <PanelTopCloseIcon className="h-6 w-6 text-gray-300" />
      </div>
      <div className="mt-4 flex items-center justify-center">
        <Avatar>
          <AvatarImage alt={pal.name} src={pal.image} />
        </Avatar>
      </div>
      <div className="text-center mt-2">
        <h2 className="text-2xl font-bold">{pal.name}</h2>
      
      </div>
      <div className="mt-4 flex items-center justify-center space-x-2">
        
        {pal.suitability.map((x)=>{return <Suitability key={x.type+pal.id+pal.name} level={x.level} suitability={x.type}></Suitability>})}
      </div>
      <div className="mt-4 text-center">
        <p className="text-sm">Food {pal.stats.food}</p>
      </div>
      <div className="mt-4 bg-gray-700 p-4 rounded-md">
        <p className="text-sm text-gray-400">
          {pal.description}
        </p>
      </div>
      <div className="mt-4">
        <p className="font-semibold">{pal.aura?.name ? pal.aura.name.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ');}):"" }</p>
        <p className="text-sm mt-2">
          {pal.aura?.description ? pal.aura.description.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ');}):"" }
        </p>
        <div className="mt-4 align-middle">
          








        <h3 className="font-semibold">Active Skills</h3>
        <div className="mt-2 grid grid-cols-7 gap-2">
          
          {pal.skills.map((skill)=>{ return <div className="text-center" key={skill.name}>{skill.name.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ');})}</div>})}
          
          
        </div>
        </div>
        <div className="mt-4 grid grid-cols-7 gap-2">
          {pal.skills.map((skill)=>{ return <div key={skill.name} className="text-center">
            <p className="font-bold">Level {skill.level}</p>
    
          </div>})}
          
        </div>
      </div>
     
      <div className="mt-4">
        <h3 className="font-semibold">Stats</h3>
        <div className="mt-2 grid grid-cols-3 gap-4">
          <div>
            <p className="text-sm">Size</p>
            <p className="text-sm font-bold">{pal.size}</p>
          </div>
          <div>
            <p className="text-sm">Rarity</p>
            <p className="text-sm font-bold">{pal.rarity}</p>
          </div>
          <div>
            <p className="text-sm">HP</p>
            <p className="text-sm font-bold">{pal.stats.hp}</p>
          </div>
          <div>
            <p className="text-sm">Melee Attack</p>
            <p className="text-sm font-bold">{pal.stats.attack.melee}</p>
          </div>
          <div>
            <p className="text-sm">Magic Attack</p>
            <p className="text-sm font-bold">{pal.stats.attack.ranged}</p>
          </div>
          <div>
            <p className="text-sm">Defense</p>
            <p className="text-sm font-bold">{pal.stats.defense}</p>
          </div>
          <div>
            <p className="text-sm">Support</p>
            <p className="text-sm font-bold">{pal.stats.support}</p>
          </div>
          {/* <div>
            <p className="text-sm">Craft Speed</p>
            <p className="text-sm font-bold">{pal.stats.}</p>
          </div> */}

        </div>

        
<Tabs className={"mt-4 w-full"}> 
    <TabList>
      <Tab>Day</Tab>
      <Tab>Night</Tab>
    </TabList>

    <TabPanel>
    <Image
      src={pal.maps.day}
      width={900}
      height={500}
      alt="Picture of the author"
    />
    </TabPanel>
    <TabPanel>
    <Image
      src={pal.maps.night}
      width={900}
      height={500}
      alt="Picture of the author"
    />
    </TabPanel>
  </Tabs>
      </div>
    </div>
    

  )
}


function PanelTopCloseIcon(props:any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
      <line x1="3" x2="21" y1="9" y2="9" />
      <path d="m9 16 3-3 3 3" />
    </svg>
  )
}
