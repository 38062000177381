"use client";
import Image from 'next/image'
export function SuitabilityFilter(props:{suitability:string }) {
  let path=""
  switch (props.suitability){
      case 'handiwork':
        path="/images/suitabilities/Handiwork_Icon.webp"
      break;

      case 'transporting':
      
      path="/images/suitabilities/Transporting_Icon.webp"
      break;

      
      case 'farming':
        
      path="/images/suitabilities/Farming_Icon.webp"
        
      break;

      
      case 'planting':
      
      path="/images/suitabilities/Planting_Icon.webp"
      break;

      
      case 'mining':
      
      path="/images/suitabilities/Mining_Icon.webp"
      break;

      
      case 'gathering':
      
      path="/images/suitabilities/Gathering_Icon.webp"
      break;

      
      case 'lumbering':
      
      path="/images/suitabilities/Lumbering_Icon.webp"
      break;

      
      case 'kindling':
      
      path="/images/suitabilities/Kindling_Icon.webp"
      break;

      
      case 'medicine_production':
        
      path="/images/suitabilities/Medicine_Production_Icon.webp"
      
      break;

      
      case 'watering':
        
      path="/images/suitabilities/Watering_Icon.webp"
      
      break;


      
      case 'cooling':
        
      path="/images/suitabilities/Cooling_Icon.webp"
      
      break;


      
      case 'generating_electricity':
        
      path="/images/suitabilities/Generating_Electricity_Icon.webp"
      
      break;

      default:
        path=""
      break;

  }


  return (
    <span className='flex flex-row p-[3px] rounded-x'>
      
        <Image
                src={path}
                width={40}
                height={40}
                alt={props.suitability}
          />
         
    </span>
  );
}
